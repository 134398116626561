html {
  font-size: 16px;
}

iframe {
  display: none;
}

.page {
  margin: auto;
  color: #fff;
  font-family: DM Sans;
  font-weight: 500;
}

.outer1 {
  z-index         : 3;
  position        : relative;
}

.main1 {
  width: 100%;
  height: 100vh;
  background: #10312F;
}

#data-1, #data-2, #data-3 {
  font-family: MuseoModerno;
}

.home-part-1 {
  background-color: #043f3d;/* rgba(4,63,61,0.75);*/
  background-repeat: no-repeat;
  background-size: 100%!important;
  background-position: top center!important;
  padding-bottom: 4rem;
  height: 100vh;
  position: relative;
}

/*  HomeV2 - page1 Start*/
.page1-font {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 22%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.page1-font-ani {
  animation: fontAni 1s ease 1 forwards;
}

@keyframes fontAni {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%);
  }
  100% {
    opacity: 1;
    transform: translate(-30%, -50%);
  }
}

.page1-tree {
  width: 43%;
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
  display: inline-block;
}

.page1-tree-ani {
  animation: treeAni 1s ease 1 forwards;
}

@keyframes treeAni {
  0% {
    transform: translate(-50%, -50%);
  }
  100% {
    transform: translate(10%, -50%);
  }
}

@media (max-width: 768px) {

.page1-tree {
  transform: translate(-50%, -130%);
}

.page1-font {
  width: 95%;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -10%);
  opacity: 1;
}

.page1-tree-ani {
  animation: none;
  transform: translate(-50%, -130%);
}

.page1-font-ani {
  animation: none;
  transform: translate(-50%, -10%);
}


  /* .page1-tree-ani {
    animation: treeAni 1s ease 1 forwards;
  }
  
  @keyframes treeAni {
    0% {
      transform: translate(-50%, -70%);
    }
    100% {
      transform: translate(-50%, -130%);
    }
  }


  .page1-font-ani {
    animation: fontAni 1s ease 1 forwards;
  }
  
  @keyframes fontAni {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%);
    }
    100% {
      opacity: 1;
      transform: translate(-50%, -10%);
    }
  } */
}


/*  HomeV2 - page1 End*/


@media (max-width: 768px) {
  .home-part-1 {
    height: 60.2rem;
    flex-direction: column-reverse!important;
    overflow: hidden;
  }
  .home-part-1 .bonsai-tree {
    padding: 0!important;
  }
  .home-part-1 .text {
    margin: 0 1rem!important;
  }
  .home-part-1 .text .head1 {
    margin: 0 0 1rem 0!important;
    line-height: 62px!important;
    font-size: 42px!important;
  }
  .home-part-1 .text h4 {
    font-size: 18px!important;
    line-height: 24px;
    margin-bottom: 1rem!important;
  }

  .home-part-1 .text .paragraph1 {
    font-size: 12px !important;
    line-height: 1.6rem;
    padding-right: 1rem;
    margin-bottom: 1.2rem !important;
  }

  .page1-tree {
    width: 80%;
  }
}


.home-part-2 {
  background-color: #10312f; /* rgba(4,63,61,0.39);*/
  background-repeat: no-repeat;
  background-size: contain!important;
  height: 100vh;
}

.home-part-2 .text {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 0 1rem!important;
}

.home-part-2 .image {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .home-part-2 {
    /* flex-direction: column!important;
    padding-bottom: 6rem; */
    height: 54.2rem;
  }
  .home-part-2 .image {
    height: auto;
    padding: 0!important;
    flex-basis: auto;
    -webkit-flex-basis: auto;
    align-items: flex-end;
  }
  .home-part-2 .text {
    padding:0 2rem!important;
    display: inline-block;
  }
  .home-part-2 .text .head1 {
    margin: 0 0 2rem 0!important;
    line-height: 48px!important;
    font-size: 32px!important;
    -webkit-text-size-adjust: none;
  }
  .home-part-2 .text .paragraph1 {
    line-height: 2rem!important;
    font-size: 12px !important;
  }
}


.home-part-3 {
  background-color: #043f3d; /* rgba(4,63,61,0.75); */
  background-repeat: no-repeat;
  background-size: 100%!important;
  background-position: top center!important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column !important;
  height: 100vh;
}

.home-part-3 .trio > .text-center > img {
  width: 40%;
}

@media (max-width: 768px) {
  .home-part-3 {
    padding-bottom: 2rem;
    padding-top: 2rem;
    height: 68rem;
  }

  .home-part-3 .trio > .text-center {
    margin-bottom: 1.4rem;
  }
  .home-part-3 .trio > .text-center > img {
    width: 21%;
    margin-bottom: 1.2rem!important;
    
  }

  .home-part-3 .head3 {
    font-size: 32px;
  }

  .home-part-3 .paragraph1 {
    padding: 0 3.2rem;
    font-size: 12px !important;
    line-height: 1.6rem;
  }
}

.home-part-4 {
  background-color: #10312f; /* rgba(4,63,61,0.39); */
  background-repeat: no-repeat;
  background-size: contain!important;
  background-position: bottom -30rem left -5rem;
  background-attachment:local;
  position: relative;
  height: 100vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-part-4 .bonsai-tree {
  width: 100%;
}

@media (max-width: 768px) {
  .home-part-4 {
    /* background-image: unset!important; */
    height: 82rem !important;
    flex-direction: column;
    padding-top: 6rem;
    padding-bottom: 6rem;
    flex: 1;
    background-size: 5rem 26rem !important;
    background-position: left -3rem bottom -22rem;
  }


  .home-part-4 .bonsai-tree {
    width: 76%;
  }

  .home-part-4 .text {
    margin: 0!important;
    padding: 0 1rem!important;
  }
  .home-part-4 .text .head1 {
    line-height: 48px !important;
    font-size: 28px !important;
    word-wrap: break-word;
  }
  .home-part-4 .text h4 {
    font-size: 1.5rem!important;
    margin-bottom: 2rem!important;
  }

  .home-part-4 .text .paragraph1 {
    font-size: 0.8rem !important;
    line-height: 2rem;
  }

  .flodLine3 {
    width: 4rem;
  }
}

.home-part-5 .left {
  /* background: linear-gradient(270deg, #0B2422 0%, rgba(11,36,34,0) 19%, rgba(11,36,34,0) 79%, #0B2422 100%); */
  background-color: rgba(18,35,34);
}

.home-part-5 .right {
  /* background: linear-gradient(270deg, #1F3C3A 0%, rgba(31,60,58,0) 19%, rgba(31,60,58,0) 79%, #1F3C3A 100%); */
  background-color: rgba(38,59,58);
}


.head1 {
  font-family  : MuseoModerno!important;
  font-weight: 600!important;
  text-align   : left;
}

.head3 {
  font-family  : MuseoModerno!important;
  font-weight: 600!important;
  margin-top: 4rem!important;
  margin-bottom: 1rem!important;
}

.head4 {
  /* font-family  : MuseoModerno!important; */
  font-family  : DM Sans !important;
  font-weight: 600!important;
  margin-top: 1rem!important;
  margin-bottom: 1rem!important;
  color: #25AE7E!important;
  letter-spacing: 0.13rem!important;
}

.head5 {
  font-family: MuseoModerno!important;
  font-weight: 600!important;
  margin-top: 2rem!important;
  margin-bottom: 1rem!important;
}

.launch-button {
  border-radius: 0 !important;
  text-transform: unset !important;
  text-align: center !important;
  font-size: 18px !important;
  line-height: 40px !important;
  font-family: DM Sans !important;
  font-weight: 500 !important;
  color: #FFFFFF;
  opacity: 1;
  background-color: #25AE7E!important;
  padding: 4px 16px !important;

}



@media (max-width: 768px) {
  .launch-button {
    font-size: 14px !important;
  }

  .desBut {
    width: 13.2rem !important;
    text-align: center;
  }
}


.home-part-4 .launch-button a {
  color: #fff;
}

.paragraph1 {
  font-size: 18px!important;
  font-family: DM Sans, sans-serif;
  line-height: 32px;
  opacity: 0.8;
}

.disabled {
  opacity: 0.5!important; 
  cursor: not-allowed!important;
}

@keyframes spin {
  from { transform: rotateY(0deg); }
  to { transform: rotateY(360deg); }
}

.bonsai-tree {
  /*
  animation: spin 10s linear infinite;
  transform-style: preserve-3d;
  */
}

.layer2 {
  height          : 3.25rem;
  border-radius   : 4px;
  background-color: rgba(236, 66, 66, 1);
  width           : 12.25rem;
  margin          : 3.75rem 0 0 36.25rem;
}

.txt1 {
  width        : 7.75rem;
  height       : 1rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 1rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 1rem;
  text-align   : center;
  margin       : 1.125rem 0 0 2.25rem;
}

.layer3 {
  width           : 58.438rem;
  height          : 19rem;
  /* border          : 1px solid rgba(151, 151, 151, 1); */
  /* background-color: rgba(216, 216, 216, 1); */
  margin          : 4.063rem 0 0 13.25rem;
}

.info1 {
  width        : 17.563rem;
  height       : 3.625rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 3rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 3.625rem;
  text-align   : center;
  margin       : 26.063rem 0 0 33.625rem;
}

.layer4 {
  z-index         : 17;
  height          : 50.375rem;
  background-color: rgba(0, 0, 0, 1);
  margin-top      : 2.25rem;
  width           : 84.688rem;
  position        : relative;
}

.main2 {
  z-index   : 18;
  height    : 47.625rem;
  background: url(./image/main2_bg.png) 100% no-repeat;
  margin-top: 2.625rem;
  width     : 84.688rem;
  position  : relative;
}

.outer2 {
  width : 18.75rem;
  height: 2.875rem;
  margin: 10.875rem 0 0 8.125rem;
}

.box1 {
  height          : 2.875rem;
  border-radius   : 4px;
  background-color: rgba(26, 26, 30, 1);
  width           : 8.875rem;
}

.box2 {
  width : 6.875rem;
  height: 1rem;
  margin: 0.938rem 0 0 1rem;
}

.txt2 {
  width        : 3.188rem;
  height       : 0.875rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 0.875rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 0.875rem;
  text-align   : left;
  margin-top   : 0.063rem;
}

.main3 {
  height          : 1rem;
  background-color: rgba(0, 0, 0, 0);
  width           : 1rem;
}

.mod1 {
  width          : 0.625rem;
  height         : 0.375rem;
  background     : url(./image/icon_down.png) 0px 0px no-repeat;
  background-size: 0.625rem 0.375rem;
  margin         : 0.375rem 0 0 0.25rem;
}

.box3 {
  height          : 2.875rem;
  border-radius   : 4px;
  background-color: rgba(19, 20, 25, 1);
  width           : 8.875rem;
}

.section3 {
  width : 6.875rem;
  height: 1rem;
  margin: 0.938rem 0 0 1rem;
}

.word3 {
  width        : 3.75rem;
  height       : 0.875rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 0.875rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 0.875rem;
  text-align   : left;
  margin-top   : 0.063rem;
}

.outer3 {
  height          : 1rem;
  background-color: rgba(0, 0, 0, 0);
  width           : 1rem;
}

.mod2 {
  width          : 0.625rem;
  height         : 0.375rem;
  background     : url(./image/icon_down.png) 0px 0px no-repeat;
  background-size: 0.625rem 0.375rem;
  margin         : 0.375rem 0 0 0.25rem;
}

.outer4 {
  width : 12.125rem;
  height: 2.25rem;
  margin: 10.25rem 0 0 17.563rem;
}

.word4 {
  width        : 12.125rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 3rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 3rem;
  text-align   : left;
}

.outer5 {
  width : 22.5rem;
  height: 2.75rem;
  margin: 3rem 0 0 17.563rem;
}

.paragraph2 {
  width        : 22.5rem;
  height       : 2.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.8);
  font-size    : 1rem;
  font-family  : Urbanist-Regular;
  line-height  : 1rem;
  text-align   : left;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.outer6 {
  width : 24.125rem;
  height: 7.938rem;
  margin: 1.063rem 0 6.625rem 60.563rem;
}

.outer7 {
  width           : 24.125rem;
  height          : 7.938rem;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(1, 5, 4, 1) 100%);
}

.outer8 {
  z-index        : 46;
  height         : 23.625rem;
  border-radius  : 8px;
  border         : 1px solid;
  background     : url(./image/covered_call_bg.png) -1px -1px no-repeat;
  background-size: 21.688rem 23.563rem;
  width          : 21.688rem;
  position       : absolute;
  left           : 45.688rem;
  top            : 16.25rem;
}

.group1 {
  width : 18.563rem;
  height: 17.375rem;
  margin: 2.063rem 0 0 1.563rem;
}

.bd1 {
  height       : 2.25rem;
  border-radius: 4px;
  background   : url(./image/covered_call_title_bg.png) 100% no-repeat;
  width        : 7.75rem;
}

.txt3 {
  width        : 6.25rem;
  height       : 0.875rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 0.875rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 0.875rem;
  text-align   : center;
  margin       : 0.688rem 0 0 0.75rem;
}

.bd2 {
  width     : 18.563rem;
  height    : 4.5rem;
  margin-top: 2.25rem;
}

.box4 {
  width : 9.688rem;
  height: 4.5rem;
}

.txt4 {
  width        : 9.688rem;
  height       : 1.5rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.4);
  font-size    : 1rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 1.5rem;
  text-align   : center;
}

.word5 {
  width        : 6.313rem;
  height       : 2.25rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 2rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 2.25rem;
  text-align   : left;
  margin-top   : 0.75rem;
}

.box5 {
  height          : 4.5rem;
  background-color: rgba(0, 0, 0, 0);
  width           : 4.5rem;
}

.group2 {
  z-index   : 53;
  height    : 4.5rem;
  background: url(./image/token_logo_bg.png) 100% no-repeat;
  width     : 4.5rem;
  position  : relative;
}

.group3 {
  width          : 0.875rem;
  height         : 1.875rem;
  background     : url(./image/eth_logo_g1.png) -0.063rem 0rem no-repeat;
  background-size: 0.938rem 1.938rem;
  margin         : 0.938rem 0 0 2.25rem;
}

.group4 {
  z-index        : 55;
  position       : absolute;
  left           : 1.438rem;
  top            : 0.938rem;
  width          : 0.875rem;
  height         : 1.875rem;
  background     : url(./image/eth_logo_g2.png) -0.063rem 0rem no-repeat;
  background-size: 0.938rem 1.938rem;
}

.group5 {
  z-index        : 59;
  position       : absolute;
  left           : 1.438rem;
  top            : 1.938rem;
  width          : 0.875rem;
  height         : 0.875rem;
  background     : url(./image/eth_logo_g3.png) -0.063rem 0rem no-repeat;
  background-size: 0.938rem 0.938rem;
}

.group6 {
  z-index        : 58;
  position       : absolute;
  left           : 2.313rem;
  top            : 1.938rem;
  width          : 0.875rem;
  height         : 0.875rem;
  background     : url(./image/eth_logo_g4.png) 0rem 0rem no-repeat;
  background-size: 0.875rem 0.938rem;
}

.group7 {
  z-index        : 57;
  position       : absolute;
  left           : 1.438rem;
  top            : 2.438rem;
  width          : 0.875rem;
  height         : 1.188rem;
  background     : url(./image/eth_logo_g5.png) -0.063rem 0rem no-repeat;
  background-size: 0.938rem 1.188rem;
}

.group8 {
  z-index        : 56;
  position       : absolute;
  left           : 2.25rem;
  top            : 2.438rem;
  width          : 0.875rem;
  height         : 1.188rem;
  background     : url(./image/eth_logo_g6.png) -0.063rem 0rem no-repeat;
  background-size: 0.938rem 1.188rem;
}

.bd3 {
  width     : 18.563rem;
  height    : 1.5rem;
  margin-top: 3rem;
}

.word6 {
  width        : 7.563rem;
  height       : 1.5rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.4);
  font-size    : 1rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 1.5rem;
  text-align   : left;
}

.txt5 {
  width        : 5.125rem;
  height       : 1.5rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 1rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 1.5rem;
  text-align   : right;
}

.bd4 {
  height          : 0.375rem;
  border-radius   : 3px;
  background-color: rgba(255, 255, 255, 0.1);
  margin-top      : 1rem;
  width           : 18.563rem;
}

.group9 {
  width           : 7.313rem;
  height          : 0.375rem;
  border-radius   : 3px;
  background-color: rgba(255, 255, 255, 1);
}

.bd5 {
  width     : 18.563rem;
  height    : 1.5rem;
  margin-top: 1rem;
}

.word7 {
  width        : 6.5rem;
  height       : 1.5rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.4);
  font-size    : 1rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 1.5rem;
  text-align   : left;
}

.txt6 {
  width        : 4.438rem;
  height       : 1.5rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 1rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 1.5rem;
  text-align   : right;
}

.outer9 {
  z-index        : 44;
  position       : absolute;
  left           : 46.063rem;
  top            : 16.625rem;
  width          : 21.688rem;
  height         : 23.25rem;
  border-radius  : 8px;
  border         : 1px solid;
  background     : url(./image/t_bg.png) -0.063rem -0.063rem no-repeat;
  background-size: 21.688rem 23.188rem;
}

.outer10 {
  z-index        : 43;
  position       : absolute;
  left           : 46.438rem;
  top            : 17rem;
  width          : 21.688rem;
  height         : 22.875rem;
  border-radius  : 8px;
  border         : 1px solid;
  background     : url(./image/t2_bg.png) -0.063rem -0.063rem no-repeat;
  background-size: 21.688rem 22.813rem;
}

.outer11 {
  z-index        : 42;
  position       : absolute;
  left           : 46.813rem;
  top            : 17.375rem;
  width          : 21.688rem;
  height         : 22.5rem;
  border-radius  : 8px;
  border         : 1px solid;
  background     : url(./image/t3_bg.png) -0.063rem -0.063rem no-repeat;
  background-size: 21.688rem 22.438rem;
}

.outer12 {
  z-index        : 41;
  position       : absolute;
  left           : 47.188rem;
  top            : 17.75rem;
  width          : 21.688rem;
  height         : 22.125rem;
  border-radius  : 8px;
  border         : 1px solid;
  background     : url(./image/t4_bg.png) -0.063rem -0.063rem no-repeat;
  background-size: 21.688rem 22.063rem;
}

.outer13 {
  z-index         : 68;
  position        : absolute;
  left            : 45.688rem;
  top             : 36.875rem;
  width           : 23.063rem;
  height          : 2.875rem;
  background-image: linear-gradient(180deg, rgba(8, 11, 12, 0) 0%, rgba(1, 5, 4, 1) 100%);
}

.outer14 {
  z-index        : 69;
  position       : absolute;
  left           : 52.063rem;
  top            : 38.625rem;
  width          : 8.875rem;
  height         : 2.25rem;
  background     : url(./image/group_btn.png) -2.5rem -2.5rem no-repeat;
  background-size: 13.875rem 7.5rem;
}

.img1 {
  z-index : 25;
  position: absolute;
  left    : 31.5rem;
  top     : 1.75rem;
  width   : 21.625rem;
  height  : 1rem;
}

.layer5 {
  width : 68.625rem;
  height: 33.813rem;
  margin: 3.063rem 0 0 8rem;
}

.mod3 {
  z-index         : 154;
  height          : 33.813rem;
  border-radius   : 12px;
  background-color: rgba(19, 20, 25, 1);
  width           : 38.75rem;
  position        : relative;
}

.mod4 {
  width     : 38.75rem;
  height    : 27.813rem;
  margin-top: 2.25rem;
}

.txt7 {
  width        : 17.375rem;
  height       : 1.25rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 1rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 1.25rem;
  text-align   : left;
  margin-left  : 1.5rem;
}

.box6 {
  width : 35.75rem;
  height: 2rem;
  margin: 1rem 0 0 1.5rem;
}

.outer15 {
  width        : 15.625rem;
  height       : 2rem;
  overflow-wrap: break-word;
  text-align   : left;
  white-space  : nowrap;
  font-size    : 0;
}

.txt8 {
  font-size    : 2rem;
  font-family  : Urbanist-SemiBold;
  color        : rgba(255, 255, 255, 1);
  line-height  : 2rem;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.info2 {
  font-size    : 1.25rem;
  font-family  : Urbanist-SemiBold;
  color        : rgba(255, 255, 255, 1);
  line-height  : 2rem;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.txt9 {
  width        : 17.375rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 0.75rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : right;
  margin-top   : 1.063rem;
}

.box7 {
  width     : 27.5rem;
  height    : 10rem;
  background: url(./image/box7_bg.png) 100% no-repeat;
  margin    : 4.5rem 0 0 2.25rem;
}

.word8 {
  width        : 6.813rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 0.75rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : center;
  margin       : 1.375rem 0 0 19.438rem;
}

.box8 {
  width        : 38.75rem;
  height       : 0.063rem;
  border-radius: 0 0 0.5px 0.5px;
  background   : url(./image/box8_bg.png) 100% no-repeat;
  margin-top   : 2rem;
}

.box9 {
  height    : 3.75rem;
  background: url(./image/box9_bg.png) 100% no-repeat;
  margin-top: 1.125rem;
  width     : 38.75rem;
}

.layer6 {
  width : 35.625rem;
  height: 1.25rem;
  margin: 1.5rem 0 0 1.5rem;
}

.info3 {
  width        : 11.875rem;
  height       : 1.25rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 1rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 1.25rem;
  text-align   : left;
}

.word9 {
  width        : 11.875rem;
  height       : 1.25rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 1rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 1.25rem;
  text-align   : left;
}

.info4 {
  width        : 11.875rem;
  height       : 1.25rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(252, 40, 85, 1);
  font-size    : 1rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 1.25rem;
  text-align   : left;
}

.mod5 {
  z-index         : 167;
  position        : absolute;
  left            : 22.563rem;
  top             : 10.563rem;
  width           : 0.5rem;
  height          : 0.5rem;
  background-color: rgba(23, 206, 185, 1);
  border-radius   : 50%;
}

.mod6 {
  z-index        : 164;
  position       : absolute;
  left           : 2.25rem;
  top            : 11rem;
  width          : 34.25rem;
  height         : 10rem;
  background     : url(./image/mod6_bg.png) -0.063rem 0rem no-repeat;
  background-size: 34.313rem 10.063rem;
}

.mod7 {
  z-index        : 165;
  position       : absolute;
  left           : 22.813rem;
  top            : 10.375rem;
  width          : 0.063rem;
  height         : 11.25rem;
  background     : url(./image/mod7_bg.png) 0rem 0rem no-repeat;
  background-size: 0.125rem 10.875rem;
}

.mod8 {
  position     : absolute;
  left         : 1.5rem;
  top          : 29.563rem;
  width        : 11.875rem;
  height       : 2rem;
  overflow-wrap: break-word;
  text-align   : left;
  white-space  : nowrap;
  font-size    : 0;
}

.txt10 {
  font-size    : 1.25rem;
  font-family  : Urbanist-SemiBold;
  color        : rgba(255, 255, 255, 1);
  line-height  : 2rem;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.info5 {
  font-size    : 2rem;
  font-family  : Urbanist-SemiBold;
  color        : rgba(255, 255, 255, 1);
  line-height  : 2rem;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.word10 {
  font-size    : 1rem;
  font-family  : Urbanist-SemiBold;
  color        : rgba(255, 255, 255, 1);
  line-height  : 2rem;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.info6 {
  z-index      : 157;
  position     : absolute;
  left         : 13.375rem;
  top          : 29.563rem;
  width        : 11.875rem;
  height       : 2rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(252, 40, 85, 1);
  font-size    : 1.25rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 2rem;
  text-align   : left;
}

.txt11 {
  z-index      : 158;
  position     : absolute;
  left         : 25.25rem;
  top          : 29.563rem;
  width        : 11.875rem;
  height       : 2rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 1.25rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 2rem;
  text-align   : left;
}

.mod9 {
  width : 28.375rem;
  height: 33.813rem;
}

.txt12 {
  width        : 17.375rem;
  height       : 1.25rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 1rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 1.25rem;
  text-align   : left;
}

.outer16 {
  height          : 7rem;
  border-radius   : 6px;
  background-color: rgba(26, 26, 30, 1);
  margin-top      : 0.813rem;
  width           : 28.375rem;
}

.section4 {
  width : 28.375rem;
  height: 6.25rem;
}

.wrap1 {
  height          : 4.75rem;
  border-radius   : 6px;
  background-color: rgba(19, 20, 25, 1);
  width           : 28.375rem;
}

.layer7 {
  width : 25.875rem;
  height: 2.25rem;
  margin: 1.25rem 0 0 1.25rem;
}

.bd6 {
  height          : 2.25rem;
  background-color: rgba(0, 0, 0, 0);
  width           : 2.25rem;
}

.wrap2 {
  z-index   : 178;
  height    : 2.25rem;
  background: url(./image/wrap2_bg.png) 100% no-repeat;
  width     : 2.25rem;
  position  : relative;
}

.main4 {
  width          : 0.438rem;
  height         : 0.938rem;
  background     : url(./image/main4_bg.png) -0.063rem 0rem no-repeat;
  background-size: 0.5rem 1rem;
  margin         : 0.5rem 0 0 1.125rem;
}

.main5 {
  z-index        : 180;
  position       : absolute;
  left           : 0.75rem;
  top            : 0.5rem;
  width          : 0.438rem;
  height         : 0.938rem;
  background     : url(./image/main5_bg.png) 0rem 0rem no-repeat;
  background-size: 0.5rem 1rem;
}

.main6 {
  z-index        : 184;
  position       : absolute;
  left           : 0.75rem;
  top            : 1rem;
  width          : 0.438rem;
  height         : 0.438rem;
  background     : url(./image/main6_bg.png) 0rem 0rem no-repeat;
  background-size: 0.5rem 0.5rem;
}

.main7 {
  z-index        : 183;
  position       : absolute;
  left           : 1.188rem;
  top            : 1rem;
  width          : 0.438rem;
  height         : 0.438rem;
  background     : url(./image/main7_bg.png) 0rem 0rem no-repeat;
  background-size: 0.438rem 0.5rem;
}

.main8 {
  z-index        : 182;
  position       : absolute;
  left           : 0.75rem;
  top            : 1.25rem;
  width          : 0.438rem;
  height         : 0.625rem;
  background     : url(./image/main8_bg.png) 0rem 0rem no-repeat;
  background-size: 0.5rem 0.625rem;
}

.main9 {
  z-index        : 181;
  position       : absolute;
  left           : 1.125rem;
  top            : 1.25rem;
  width          : 0.438rem;
  height         : 0.625rem;
  background     : url(./image/main9_bg.png) -0.063rem 0rem no-repeat;
  background-size: 0.5rem 0.625rem;
}

.bd7 {
  width : 6.688rem;
  height: 2rem;
  margin: 0.125rem 0 0 0.75rem;
}

.word11 {
  width        : 3.063rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 0.75rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : center;
}

.word12 {
  width        : 6.688rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 0.75rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : left;
  margin-top   : 0.5rem;
}

.word13 {
  width        : 4.875rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(252, 40, 85, 1);
  font-size    : 0.75rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : center;
  margin       : 0.875rem 0 0 2.75rem;
}

.bd8 {
  width : 6.688rem;
  height: 2rem;
  margin: 0.125rem 0 0 1.875rem;
}

.txt13 {
  width        : 2.563rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 0.75rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : right;
  margin-left  : 4.125rem;
}

.info7 {
  width        : 6.688rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 0.75rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : right;
  margin-top   : 0.5rem;
}

.wrap3 {
  width : 25.875rem;
  height: 0.75rem;
  margin: 0.75rem 0 0 1.25rem;
}

.info8 {
  width        : 6.688rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 0.75rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : left;
}

.word14 {
  width        : 6.688rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 0.75rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : right;
}

.word15 {
  width        : 11.875rem;
  height       : 1.25rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 1rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 1.25rem;
  text-align   : left;
  margin-top   : 1.5rem;
}

.outer17 {
  height          : 4.75rem;
  border-radius   : 6px;
  background-color: rgba(19, 20, 25, 1);
  margin-top      : 0.75rem;
  width           : 28.375rem;
}

.box10 {
  width : 25.875rem;
  height: 2.25rem;
  margin: 1.25rem 0 0 1.25rem;
}

.mod10 {
  height          : 2.25rem;
  background-color: rgba(0, 0, 0, 0);
  width           : 2.25rem;
}

.wrap4 {
  height    : 2.25rem;
  background: url(./image/wrap4_bg.png) 100% no-repeat;
  width     : 2.25rem;
}

.label2 {
  width : 0.75rem;
  height: 0.875rem;
  margin: 0.688rem 0 0 0.75rem;
}

.mod11 {
  width : 10.5rem;
  height: 2rem;
  margin: 0.125rem 0 0 0.75rem;
}

.info9 {
  width        : 3.063rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(82, 104, 208, 1);
  font-size    : 0.75rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : center;
}

.word16 {
  width        : 10.5rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 0.75rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : left;
  margin-top   : 0.5rem;
}

.mod12 {
  width : 6.688rem;
  height: 2rem;
  margin: 0.125rem 0 0 5.688rem;
}

.word17 {
  width        : 3.125rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 0.75rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : right;
  margin-left  : 3.563rem;
}

.word18 {
  width        : 6.688rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 0.75rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : right;
  margin-top   : 0.5rem;
}

.outer18 {
  height          : 4.75rem;
  border-radius   : 6px;
  background-color: rgba(19, 20, 25, 1);
  margin-top      : 0.75rem;
  width           : 28.375rem;
}

.wrap5 {
  width : 25.875rem;
  height: 2.25rem;
  margin: 1.25rem 0 0 1.25rem;
}

.mod13 {
  height          : 2.25rem;
  background-color: rgba(0, 0, 0, 0);
  width           : 2.25rem;
}

.wrap6 {
  height    : 2.25rem;
  background: url(./image/wrap6_bg.png) 100% no-repeat;
  width     : 2.25rem;
}

.icon1 {
  width : 0.75rem;
  height: 0.875rem;
  margin: 0.688rem 0 0 0.75rem;
}

.mod14 {
  width : 10.5rem;
  height: 2rem;
  margin: 0.125rem 0 0 0.75rem;
}

.word19 {
  width        : 3.063rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(82, 104, 208, 1);
  font-size    : 0.75rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : center;
}

.txt14 {
  width        : 10.5rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 0.75rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : left;
  margin-top   : 0.5rem;
}

.mod15 {
  width : 6.688rem;
  height: 2rem;
  margin: 0.125rem 0 0 5.688rem;
}

.info10 {
  width        : 3.375rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 0.75rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : right;
  margin-left  : 3.313rem;
}

.info11 {
  width        : 6.688rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 0.75rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : right;
  margin-top   : 0.5rem;
}

.outer19 {
  height          : 4.75rem;
  border-radius   : 6px;
  background-color: rgba(19, 20, 25, 1);
  margin-top      : 0.75rem;
  width           : 28.375rem;
}

.group10 {
  width : 25.875rem;
  height: 2.25rem;
  margin: 1.25rem 0 0 1.25rem;
}

.icon2 {
  width : 2.25rem;
  height: 2.25rem;
}

.section5 {
  width : 10.5rem;
  height: 2rem;
  margin: 0.125rem 0 0 0.75rem;
}

.txt15 {
  width        : 3.063rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(82, 104, 208, 1);
  font-size    : 0.75rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : center;
}

.word20 {
  width        : 10.5rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 0.75rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : left;
  margin-top   : 0.5rem;
}

.section6 {
  width : 6.688rem;
  height: 2rem;
  margin: 0.125rem 0 0 5.688rem;
}

.txt16 {
  width        : 3.125rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 0.75rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : right;
  margin-left  : 3.563rem;
}

.word21 {
  width        : 6.688rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 0.75rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : right;
  margin-top   : 0.5rem;
}

.outer20 {
  height          : 4.75rem;
  border-radius   : 6px;
  background-color: rgba(19, 20, 25, 1);
  margin-top      : 0.75rem;
  width           : 28.375rem;
}

.box11 {
  width : 25.875rem;
  height: 2.25rem;
  margin: 1.25rem 0 0 1.25rem;
}

.icon3 {
  width : 2.25rem;
  height: 2.25rem;
}

.bd9 {
  width : 10.5rem;
  height: 2rem;
  margin: 0.125rem 0 0 0.75rem;
}

.word22 {
  width        : 3.063rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(82, 104, 208, 1);
  font-size    : 0.75rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : center;
}

.word23 {
  width        : 10.5rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 0.75rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : left;
  margin-top   : 0.5rem;
}

.bd10 {
  width : 6.688rem;
  height: 2rem;
  margin: 0.125rem 0 0 5.688rem;
}

.info12 {
  width        : 3.375rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 0.75rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : right;
  margin-left  : 3.313rem;
}

.word24 {
  width        : 6.688rem;
  height       : 0.75rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 0.75rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 0.75rem;
  text-align   : right;
  margin-top   : 0.5rem;
}

.layer8 {
  width : 25rem;
  height: 3.625rem;
  margin: 12.5rem 0 0 29.875rem;
}

.word25 {
  width        : 10.313rem;
  height       : 3.625rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 3rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 3.625rem;
  text-align   : center;
}

.img2 {
  width : 13.688rem;
  height: 3.625rem;
}

.txt17 {
  width        : 46.938rem;
  height       : 1rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 1rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 1rem;
  text-align   : center;
  margin       : 1.5rem 0 0 18.875rem;
}

.img3 {
  width : 21.625rem;
  height: 1rem;
  margin: 1.5rem 0 0 31.5rem;
}

.layer9 {
  width          : 68.625rem;
  height         : 18.5rem;
  justify-content: space-between;
  margin         : 4rem 0 0 8rem;
}

.layer10-0 {
  height       : 18.5rem;
  border-radius: 12px;
  margin-right : 1.5rem;
  width        : 21.875rem;
  background   : rgba(22, 6, 20, 1);
}

.bd11-0 {
  width : 17.375rem;
  height: 12.25rem;
  margin: 2.25rem 0 0 2.25rem;
}

.label3-0 {
  width : 3rem;
  height: 3rem;
}

.txt18-0 {
  width        : 17.375rem;
  height       : 1.25rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 1.25rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 1.25rem;
  text-align   : left;
  margin-top   : 1.5rem;
}

.word26-0 {
  width        : 17.375rem;
  height       : 4.5rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 1rem;
  font-family  : Urbanist-Regular;
  line-height  : 1rem;
  text-align   : left;
  margin-top   : 2rem;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.layer10-1 {
  height       : 18.5rem;
  border-radius: 12px;
  margin-right : 1.5rem;
  width        : 21.875rem;
  background   : rgba(18, 20, 12, 1);
}

.bd11-1 {
  width : 17.375rem;
  height: 12.25rem;
  margin: 2.25rem 0 0 2.25rem;
}

.label3-1 {
  width : 3rem;
  height: 3rem;
}

.txt18-1 {
  width        : 17.375rem;
  height       : 1.25rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 1.25rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 1.25rem;
  text-align   : left;
  margin-top   : 1.5rem;
}

.word26-1 {
  width        : 17.375rem;
  height       : 4.5rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 1rem;
  font-family  : Urbanist-Regular;
  line-height  : 1rem;
  text-align   : left;
  margin-top   : 2rem;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.layer10-2 {
  height       : 18.5rem;
  border-radius: 12px;
  margin-right : 1.5rem;
  width        : 21.875rem;
  background   : rgba(10, 16, 23, 1);
}

.bd11-2 {
  width : 17.375rem;
  height: 12.25rem;
  margin: 2.25rem 0 0 2.25rem;
}

.label3-2 {
  width : 3rem;
  height: 3rem;
}

.txt18-2 {
  width        : 17.375rem;
  height       : 1.25rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 1.25rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 1.25rem;
  text-align   : left;
  margin-top   : 1.5rem;
}

.word26-2 {
  width        : 17.375rem;
  height       : 4.5rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 1rem;
  font-family  : Urbanist-Regular;
  line-height  : 1rem;
  text-align   : left;
  margin-top   : 2rem;
  overflow     : hidden;
  text-overflow: ellipsis;
}

.layer11 {
  height          : 42.375rem;
  background-color: rgba(0, 0, 0, 1);
  margin-top      : 4.125rem;
  width           : 84.688rem;
}

.outer21 {
  width : 68.625rem;
  height: 24.125rem;
  margin: 7.5rem 0 0 8rem;
}

.word27 {
  width        : 29.063rem;
  height       : 3.625rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 3rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 3.625rem;
  text-align   : center;
  margin-left  : 19.813rem;
}

.main10 {
  width     : 68.625rem;
  height    : 15.5rem;
  margin-top: 5rem;
  flex-wrap : wrap;
}

.bd12 {
  height          : 6.25rem;
  background-color: rgba(0, 0, 0, 1);
  width           : 21.875rem;
  margin          : 0 1.5rem 3rem 0;
}

.img4 {
  width : 15.625rem;
  height: 3.688rem;
  margin: 1.313rem 0 0 3.125rem;
}

.bd13 {
  height          : 6.25rem;
  background-color: rgba(0, 0, 0, 1);
  width           : 21.875rem;
  margin          : 0 1.5rem 3rem 0;
}

.pic1 {
  width : 18.875rem;
  height: 5rem;
  margin: 0.625rem 0 0 1.5rem;
}

.bd14 {
  height          : 6.25rem;
  background-color: rgba(0, 0, 0, 1);
  margin-bottom   : 3rem;
  width           : 21.875rem;
}

.pic2 {
  width : 14.938rem;
  height: 5rem;
  margin: 0.625rem 0 0 3.5rem;
}

.bd15 {
  height          : 6.25rem;
  background-color: rgba(0, 0, 0, 1);
  width           : 21.875rem;
  margin          : 0 1.5rem 3rem 0;
}

.img5 {
  width : 17.438rem;
  height: 4.625rem;
  margin: 0.813rem 0 0 2.25rem;
}

.bd16 {
  height          : 6.25rem;
  background-color: rgba(0, 0, 0, 1);
  width           : 21.875rem;
  margin          : 0 1.5rem 3rem 0;
}

.pic3 {
  width : 14.75rem;
  height: 4.063rem;
  margin: 1.125rem 0 0 3.563rem;
}

.bd17 {
  height          : 6.25rem;
  background-color: rgba(0, 0, 0, 1);
  margin-bottom   : 3rem;
  width           : 21.875rem;
}

.img6 {
  width : 10.625rem;
  height: 4.875rem;
  margin: 0.688rem 0 0 5.625rem;
}

.main11 {
  z-index   : 6;
  height    : 32.625rem;
  background: url(./image/main11_bg.png) 100% no-repeat;
  width     : 60.5rem;
  position  : absolute;
  left      : 12.125rem;
  top       : 32.25rem;
}

.section7 {
  z-index   : 7;
  height    : 19.125rem;
  background: url(./image/section7_bg.png) 100% no-repeat;
  width     : 58.438rem;
  position  : relative;
  margin    : 1.063rem 0 0 1.125rem;
}

.box12 {
  width          : 3.5rem;
  height         : 1.063rem;
  background     : url(./image/box12_bg.png) 0.063rem 0rem no-repeat;
  background-size: 3.25rem 1.063rem;
  margin         : 1.063rem 0 0 3.563rem;
}

.box13 {
  z-index         : 9;
  position        : absolute;
  left            : 2.375rem;
  top             : 0.938rem;
  width           : 5.75rem;
  height          : 1.25rem;
  background-color: rgba(37, 39, 49, 1);
}

.main12 {
  z-index         : 144;
  height          : 11.5rem;
  border-radius   : 48px;
  border          : 1px solid;
  background-color: rgba(0, 0, 0, 1);
  width           : 68.75rem;
  position        : absolute;
  left            : 8rem;
  top             : 62.375rem;
}

.box14 {
  width          : 62.75rem;
  height         : 4.313rem;
  justify-content: space-between;
  margin         : 3.25rem 0 0 3rem;
}

.bd18-0 {
  width : 15.688rem;
  height: 4.313rem;
}

.word28-0 {
  width        : 15.688rem;
  height       : 2.375rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 2rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 2.375rem;
  text-align   : center;
}

.info13-0 {
  width        : 15.688rem;
  height       : 1.188rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 1rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 1.188rem;
  text-align   : center;
  margin-top   : 0.75rem;
}

.bd18-1 {
  width : 15.688rem;
  height: 4.313rem;
}

.word28-1 {
  width        : 15.688rem;
  height       : 2.375rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 2rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 2.375rem;
  text-align   : center;
}

.info13-1 {
  width        : 15.688rem;
  height       : 1.188rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 1rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 1.188rem;
  text-align   : center;
  margin-top   : 0.75rem;
}

.bd18-2 {
  width : 15.688rem;
  height: 4.313rem;
}

.word28-2 {
  width        : 15.688rem;
  height       : 2.375rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 2rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 2.375rem;
  text-align   : center;
}

.info13-2 {
  width        : 15.688rem;
  height       : 1.188rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 1rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 1.188rem;
  text-align   : center;
  margin-top   : 0.75rem;
}

.bd18-3 {
  width : 15.688rem;
  height: 4.313rem;
}

.word28-3 {
  width        : 15.688rem;
  height       : 2.375rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 2rem;
  font-family  : Urbanist-SemiBold;
  white-space  : nowrap;
  line-height  : 2.375rem;
  text-align   : center;
}

.info13-3 {
  width        : 15.688rem;
  height       : 1.188rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 0.6);
  font-size    : 1rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 1.188rem;
  text-align   : center;
  margin-top   : 0.75rem;
}

.word29 {
  z-index      : 23;
  position     : absolute;
  left         : 18.875rem;
  top          : 81.2rem;
  width        : 46.938rem;
  height       : 1rem;
  display      : block;
  overflow-wrap: break-word;
  color        : rgba(255, 255, 255, 1);
  font-size    : 1rem;
  font-family  : Urbanist-Regular;
  white-space  : nowrap;
  line-height  : 1rem;
  text-align   : center;
}

/* loadingIcon animation */
.animation1 {
  animation: aniUp .5s linear 1 forwards;
  -webkit-animation: aniUp .5s linear 1 forwards;
}

@keyframes aniUp
{
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

.home-part-2 .flodLine1 {
  width: 100%;
  position: absolute;
  top: 0;
  transform: translate(0, -50%);
}


.home-part-3 .flodLine2 {
  width: 100%;
  position: absolute;
  top: 0;
  transform: translate(0, -50%);
}

.home-part-4 .flodLine3 {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(0, -50%);
}



