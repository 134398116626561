$container-max-widths: (
  xl: 1200px,
);

.py-6 {
  padding-top: ($spacer * 5) !important;
  padding-bottom: ($spacer * 5) !important;
}

#root {
  transition: filter 0.3s ease-in-out;
}

.backdrop-filter {
  content: "";
  position: absolute;
  width: 100%;
  height: 100vh;
  filter: blur(2px);
}