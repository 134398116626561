.outer22 {
    z-index: 107;
    height: 160px;
    background-color: rgba(0, 0, 0, 1);
    width: 1355px;
    margin: auto;
   
    .section12 {
      width: 1255px;
      height: 54px;
      margin: 53px 0 0 50px;
      .section8 {
        width: 194px;
        height: 36px;
        margin-top: 9px;
        .word30 {
          width: 194px;
          height: 12px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 0.4);
          font-size: 12px;
          font-family: Urbanist-Regular;
          white-space: nowrap;
          line-height: 12px;
          text-align: left;
        }
        .info14 {
          width: 194px;
          height: 12px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 0.4);
          font-size: 12px;
          font-family: Urbanist-Regular;
          white-space: nowrap;
          line-height: 12px;
          text-align: left;
          margin-top: 12px;
        }
      }
      .pic5 {
        width: 120px;
        height: 54px;
        margin-left: 374px;
      }
      .section10 {
        height: 36px;
        background-color: rgba(0, 0, 0, 0);
        width: 36px;
        margin: 9px 0 0 463px;
        .box15 {
          width: 30px;
          height: 24px;
          background: url(../../assets/images/footer_icon_1.png)
            0px 0px no-repeat;
          margin: 7px 0 0 4px;
        }
      }
      .section11 {
        height: 36px;
        background-color: rgba(0, 0, 0, 0);
        width: 36px;
        margin: 9px 0 0 32px;
        .icon4 {
          width: 30px;
          height: 17px;
          margin: 10px 0 0 3px;
        }
      }
    }
  }

.pc-footer {
  background-color: #10312F;
  padding: 2rem;


  .text {
    font-size: 0.8rem;
    font-family: DM Sans;
    font-weight: 400;
    line-height: 3rem;
    color: #FFFFFF;
    letter-spacing: 0.05rem;
    opacity: 0.8;
  }

  .logo {
    width: 3rem;
    height: 3rem;
  }

  .socials {
    height: 3rem;

    img {
      height: 0.8rem;
      margin: 1.1rem 0.8rem;
      opacity: 0.15;
    }
    img.good {
      opacity: 0.8;
    }
  }

  @media (max-width: 768px) {
    .rights {
      text-align: center;
      order: 3;
      margin-top: -1rem;
    }
    .socials {
      text-align: center!important;
    }
  }
}